import Image from "next/image"
import Link from "next/link"
import { useRouter } from "next/router"

function Logo() {
  const router = useRouter()
  return (
    <a href="/">
      {!router.pathname.startsWith("/play") && (
        <Image
          src="/logo.svg"
          alt="ULTRAHORSE Market"
          width={210}
          height={50}
          priority={true}
        />
      )}
      {router.pathname.startsWith("/play") && (
        <Image
          src="/sb-logo_web.png"
          alt="SquadBlast"
          width={150}
          height={50}
          priority={true}
        />
      )}
    </a>
  )
}

export default Logo
