import Button from "@/components/form/base/Button"
import { useStore } from "lib/zustandProvider"
import Hamburger from "./Hamburger"
import Logo from "./Logo"
import UserInfo from "./UserInfo"
import HeaderNavPC from "../navigation/HeaderNavPC"
import { useRouter } from "next/router"
import { isMobile } from "react-device-detect"
import { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faExpand,
  faVolumeMute,
  faVolumeUp
} from "@fortawesome/free-solid-svg-icons"

function Header() {
  const [renderClientSide, setRenderClientSide] = useState(false)
  const router = useRouter()
  const user = useStore((state) => state.user)
  const babkaToken = useStore((state) => state.babkaToken)
  const devMode = useStore((state) => state.devMode)
  const setLoginVisible = useStore((state) => state.setLoginVisible)
  const goFullScreen = useStore((state) => state.goFullScreen)
  const volume = useStore((state) => state.volume)
  const setVolume = useStore((state) => state.setVolume)
  const isGameLoading = useStore((state) => state.isGameLoading)

  function toggleVolume() {
    setVolume(volume === 100 ? 0 : 100)
  }

  useEffect(() => {
    setRenderClientSide(true)
    if (typeof window !== "undefined" && window.localStorage) {
      const savedVolume = window.localStorage.getItem("pageVolume")
      setVolume(
        savedVolume !== null && savedVolume !== "NaN"
          ? parseInt(savedVolume)
          : 100
      )
    }
  }, [])

  return (
    <header className="w-full h-20 px-4 lg:px-8 bg-black bg-opacity-20 text-white flex flex-row items-center justify-between">
      <Logo />
      <HeaderNavPC />
      <span className="flex-grow"></span>
      {renderClientSide && router.pathname.startsWith("/play") && !isMobile && (
        <div className="flex gap-4 mr-4">
          <Button onClick={toggleVolume} type={["gray"]}>
            {volume === 0 && (
              <FontAwesomeIcon icon={faVolumeMute} className="w-6 h-6" />
            )}
            {volume > 0 && (
              <FontAwesomeIcon icon={faVolumeUp} className="w-6 h-6" />
            )}
          </Button>
          {!isGameLoading && (
            <Button onClick={goFullScreen} type={["gray"]}>
              <FontAwesomeIcon icon={faExpand} className="w-6 h-6" />
            </Button>
          )}
        </div>
      )}
      {user.authenticated ? (
        <>
          <UserInfo />
          <Hamburger />
          {devMode && <div className="w-14"></div>}
        </>
      ) : (
        <>
          {router.pathname.startsWith("/play/chromeos") && (
            <Button
              onClick={() => {
                window.onbeforeunload = null
                window.location.href = "/?login"
              }}
            >
              Login
            </Button>
          )}
          {!router.pathname.startsWith("/play/chromeos") && (
            <Button
              onClick={() => {
                setLoginVisible(true)
              }}
            >
              Login
            </Button>
          )}
          {devMode && <div className="w-14"></div>}
        </>
      )}
    </header>
  )
}

export default Header
