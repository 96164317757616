import { useStore } from "lib/zustandProvider"
import Button from "../form/base/Button"
import Popup from "./base/Popup"
import PopupHeader from "./base/PopupHeader"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons"

function ExternalLink() {
  const isOpen = useStore((state) => state.externalLinkPromptVisible)
  const link = useStore((state) => state.externalLink)
  const hideExternalLinkPrompt = useStore(
    (state) => state.hideExternalLinkPrompt
  )

  const onClose = () => {
    hideExternalLinkPrompt()
  }

  const onContinue = () => {
    window.open(link, "_blank")
    onClose()
  }

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      className="font-roboto"
      dialogPosition="top-2 left-0 right-0 lg:top-0 lg:bottom-0"
      zIndex="z-50"
      skipBackdrop={false}
    >
      <PopupHeader title="SquadBlast wants to open a link" onClose={onClose} />
      <div className="my-6 mx-8 flex flex-col justify-center items-center">
        <p className="text-white text-opacity-70">
          Click the link to continue:
        </p>
        <div className="my-4 flex flex-row gap-4">
          <Button
            type={["blue", "large"]}
            onClick={onContinue}
            className="flex flex-row gap-4 items-center"
          >
            <span className="flex-1">{link.substring(0, 30)}... </span>
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="w-5" />
          </Button>
        </div>
        <p className="text-gray-200 text-xs italic text-opacity-70">
          The link will open in a new tab, so the game will not quit.
        </p>
      </div>
    </Popup>
  )
}

export default ExternalLink
