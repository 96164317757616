import { useEffect, useState } from "react"

function XSollaWidget() {
  const [widget, setWidget] = useState(null)

  const mountWidget = async () => {
    if (typeof window !== "undefined") {
      const XsollaLogin = await import("@xsolla/login-sdk")
      const widget = new XsollaLogin.Widget({
        projectId: process.env.NEXT_PUBLIC_XSOLLA_LOGIN_PROJECT,
        preferredLocale: "en_US",
        clientId: parseInt(process.env.NEXT_PUBLIC_XSOLLA_CLIENT_ID),
        responseType: "code",
        state: encodeURIComponent(window.location.href),
        scope: "offline",
        redirectUri: `${process.env.NEXT_PUBLIC_SERVER_URL}/user/xsolla`
      })
      widget.mount("xl_auth")
      setWidget(widget)
      console.log("XSOLLA Widget Mounted", widget)
    }
  }

  const unmountWidget = () => {
    if (widget) {
      widget.unmount()
      setWidget(null)
      console.log("XSOLLA widget unmounted")
    }
  }

  useEffect(() => {
    window.onbeforeunload = null
    mountWidget()

    return () => {
      unmountWidget()
    }
  }, [])

  return (
    <div
      id="xl_auth"
      style={{
        width: "375px",
        height: "550px",
        position: "relative",
        left: "-10px",
        overflow: "hidden"
      }}
    ></div>
  )
}

export default XSollaWidget
