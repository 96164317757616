import { useEffect } from "react"
import { Router } from "next/router"

export function usePageViews({ gaMeasurementId, ignoreHashChange, disabled }) {
  useEffect(() => {
    if (disabled) {
      return
    }

    const handleRouteChange = (url) => {
      const _gaMeasurementId =
        process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID ?? gaMeasurementId

      pageView({ path: url.toString() }, _gaMeasurementId)
    }

    Router.events.on("routeChangeComplete", handleRouteChange)

    if (!ignoreHashChange) {
      Router.events.on("hashChangeComplete", handleRouteChange)
    }

    return () => {
      Router.events.off("routeChangeComplete", handleRouteChange)

      if (!ignoreHashChange) {
        Router.events.off("hashChangeComplete", handleRouteChange)
      }
    }
  }, [Router.events, gaMeasurementId, ignoreHashChange])
}

export function pageView(
  { title, location, path, sendPageView, userId },
  measurementId
) {
  const gaMeasurementId =
    process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID ?? measurementId

  if (!gaMeasurementId || !window.gtag) {
    return
  }

  const pageViewOptions = {}

  if (title !== undefined) {
    pageViewOptions.page_title = title
  }

  if (location !== undefined) {
    pageViewOptions.page_location = location
  }

  if (path !== undefined) {
    pageViewOptions.page_path = path
  }

  if (sendPageView !== undefined) {
    pageViewOptions.send_page_view = sendPageView
  }

  if (userId !== undefined) {
    pageViewOptions.user_id = userId
  }

  window.gtag("config", gaMeasurementId, pageViewOptions)
}
