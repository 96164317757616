import SimpleBar from "simplebar-react"
import NavigationItems from "./NavigationItems"
import "simplebar-react/dist/simplebar.min.css"

function MainNavigation() {
  const classesActive =
    "text-amber-600 bg-slate-300 bg-opacity-10 h-3/4 flex items-center px-6"
  const classesInactive =
    "text-slate-300 hover:text-amber-500 hover:bg-slate-300 hover:bg-opacity-10 h-3/4 flex items-center px-6"
  return (
    <div className="tablet:hidden w-full h-12 bg-black bg-opacity-40">
      <SimpleBar style={{ maxWidth: "100%", height: "3rem" }}>
        <div
          className={`h-12 flex flex-row items-center px-2 text-white gap-2`}
        >
          <NavigationItems
            classesActive={classesActive}
            classesInactive={classesInactive}
          />
        </div>
      </SimpleBar>
    </div>
  )
}

export default MainNavigation
