import { isAndroid, isIOS, isMobile } from "react-device-detect"

export const inspectItemDeepLink = (itemId, type, user) => {
  const requestType = type === "equipment" ? "vanity-item" : type
  if (isMobile) {
    if (isAndroid) {
      return `squadblast://runtime/?inspect-${requestType}-by-type-id=${itemId}`
    } else if (isIOS) {
      return `squadblast://runtime/?inspect-${requestType}-by-type-id=${itemId}`
    }
  }

  console.log(user)

  const inspectPart = encodeURIComponent(
    `inspect-${requestType}-by-type-id ${itemId}`
  )

  if (
    user?.lastGamePlatform === "Standalone_x" ||
    user?.lastGamePlatform === "url_standaloneX"
  ) {
    const expiresIn = new Date()
    expiresIn.setHours(expiresIn.getHours() + 1)
    return `xl-3176://game/202429?payload=${inspectPart}` //&expires_in=${expiresIn.getTime()}`
  }

  if (
    user?.lastGamePlatform === "android_cloud" ||
    user?.lastGamePlatform === "url_androidCloud"
  ) {
    return `https://beta.squadblast.com/?inspect-${requestType}-by-type-id=${itemId}`
  }

  if (
    user?.lastGamePlatform === "web" ||
    user?.lastGamePlatform === "url_web"
  ) {
    return `https://market.squadblast.com/play?inspect-${requestType}-by-type-id=${itemId}`
  }

  return `steam://run/2270790//+${inspectPart}` //Demo
  // return `steam://run/2270790?inspect-${requestType}-by-type-id=${itemId}` //Demo
  // return "steam://run/2080820" //Actual Game?
}

export const normalizeServerPlatform = (serverPlatform) => {
  switch (serverPlatform) {
    case "apple_app_store":
      return "ios"
    case "google_play":
      return "android"

    default:
      return serverPlatform
  }
}

export const getDevicePlatform = () => {
  if (isMobile) {
    if (isAndroid) {
      return "android"
    } else if (isIOS) {
      return "ios"
    }
  }

  return "steam"
}

export const getDeviceInstallLink = () => {
  if (isMobile) {
    if (isAndroid) {
      return "https://play.google.com/store/apps/details?id=com.ultrahorse.squadblast"
    } else if (isIOS) {
      return "https://apps.apple.com/lv/app/squadblast/id6444757950"
    }
  }

  return "https://installer.launcher.xsolla.com/xlauncher-builds/xsolla-launcher-update/5006/bin/web_installer.exe"
}

export const getDeviceDeeplink = (user, path) => {
  if (isMobile) {
    if (isAndroid) {
      return `squadblast://runtime/${path ? path : ""}`
    } else if (isIOS) {
      return `squadblast://${path ? path : ""}`
    }
  }

  if (
    user?.lastGamePlatform === "Standalone_x" ||
    user?.lastGamePlatform === "url_standaloneX"
  ) {
    return "xl-3176://game/11117"
  }

  if (
    user?.lastGamePlatform === "android_cloud" ||
    user?.lastGamePlatform === "url_androidCloud"
  ) {
    return `https://beta.squadblast.com/`
  }

  if (
    user?.lastGamePlatform === "web" ||
    user?.lastGamePlatform === "url_web"
  ) {
    if (window) {
      window.close()
    }
    return `https://market.squadblast.com/play`
  }

  return "steam://run/2270790" //Demo
  // return "steam://run/2080820" //Actual Game?
}

export const getDeviceToastPosition = () => {
  if (isMobile) {
    return "top-center"
  }

  return "bottom-right"
}
