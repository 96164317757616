import { useStore } from "lib/zustandProvider"
import Image from "next/image"
import { useEffect } from "react"
import { toast } from "react-hot-toast"
import Notifications from "./Notifications"

function UserInfo({ className }) {
  const user = useStore((state) => state.user)
  const devMode = useStore((state) => state.devMode)

  useEffect(() => {
    if (window.localStorage.getItem("welcomeToast") === "issue") {
      window.localStorage.setItem("welcomeToast", "complete")
      setTimeout(() => {
        toast(`Welcome, ${user.username}!`, {
          icon: "👏",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff"
          }
        })
      }, 1000)
    }
  }, [])

  return (
    <div className={`flex flex-row ${className}`}>
      {/* {devMode && <Notifications />} */}
      <div className="flex flex-col mx-3 items-end text-xl leading-6">
        <span className="font-tahoma mb-1">{user.username}</span>
        {/* <span>UC {user.balance.toFixed(2)}</span> */}
        <span></span>
      </div>
      {/* {devMode && (
        <div>
          <Image
            src={`${user.image}`}
            width={50}
            height={50}
            className="border border-white"
            alt="User Profile Picture"
          />
        </div>
      )} */}
    </div>
  )
}

export default UserInfo
