import Link from "next/link"

function Footer() {
  return (
    <footer className="w-full h-min-20 px-8 pb-3 pt-5 bg-slate-300 mt-auto text-xs flex flex-col gap-3 items-center">
      <img
        src={`${process.env.NEXT_PUBLIC_CDN_URL}/email/Footer/PlayNow/PlayNow.png`}
        alt="Play Now For Free"
      />
      <div className="flex flex-row gap-2 flex-wrap justify-center">
        <a
          href="https://installer.launcher.xsolla.com/xlauncher-builds/xsolla-launcher-update/5567/bin/web_installer.exe"
          target="_blank"
        >
          <img
            src={`${process.env.NEXT_PUBLIC_CDN_URL}/email/Footer/Platform/PC.png`}
            alt="PC"
          />
        </a>
        <a href="https://cloud.squadblast.com" target="_blank">
          <img
            src={`${process.env.NEXT_PUBLIC_CDN_URL}/email/Footer/Platform/Web.png`}
            alt="Web"
          />
        </a>
        <a
          href="https://store.steampowered.com/app/2080820?medium=main_page&utm_campaign=early_beta&utm_source=website"
          target="_blank"
        >
          <img
            src={`${process.env.NEXT_PUBLIC_CDN_URL}/email/Footer/Platform/Steam.png`}
            alt="Steam"
          />
        </a>
        <a
          href="https://news.xbox.com/en-us/2023/04/10/join-the-squadblast-early-beta-on-xbox/"
          target="_blank"
        >
          <img
            src={`${process.env.NEXT_PUBLIC_CDN_URL}/email/Footer/Platform/Xboxone.png`}
            alt="Xbox One"
          />
        </a>
        <a
          href="https://news.xbox.com/en-us/2023/04/10/join-the-squadblast-early-beta-on-xbox/"
          target="_blank"
        >
          <img
            src={`${process.env.NEXT_PUBLIC_CDN_URL}/email/Footer/Platform/XboxXS.png`}
            alt="Xbox Xs"
          />
        </a>
        <a
          href="https://store.playstation.com/en-us/concept/10007616/"
          target="_blank"
        >
          <img
            src={`${process.env.NEXT_PUBLIC_CDN_URL}/email/Footer/Platform/PlayStation.png`}
            alt="PlayStation"
          />
        </a>
        <img
          src={`${process.env.NEXT_PUBLIC_CDN_URL}/email/Footer/Platform/NintendoSwitch.png`}
          alt="Nintendo Switch"
        />
        <a
          href="https://apps.apple.com/app/squadblast/id6444757950"
          target="_blank"
        >
          <img
            src={`${process.env.NEXT_PUBLIC_CDN_URL}/email/Footer/Platform/AppStore.png`}
            alt="AppStore"
          />
        </a>
        <a href="https://gsght.com/c/1he3z2" target="_blank">
          <img
            src={`${process.env.NEXT_PUBLIC_CDN_URL}/email/Footer/Platform/GooglePlay.png`}
            alt="Google Play"
          />
        </a>
      </div>
      <div className="flex flex-row gap-2 flex-wrap">
        <img
          src={`${process.env.NEXT_PUBLIC_CDN_URL}/email/Footer/StayConnected/StayConnected.png`}
          alt="Stay Connected On"
        />
        <a href="http://discord.gg/squadblast" target="_blank">
          <img
            src={`${process.env.NEXT_PUBLIC_CDN_URL}/email/Footer/Social/discordblack.png`}
            alt="Discord"
          />
        </a>
        <a
          href="https://www.youtube.com/channel/UCP_uFo6vd5R63qSntuhsWTQ"
          target="_blank"
        >
          <img
            src={`${process.env.NEXT_PUBLIC_CDN_URL}/email/Footer/Social/youtubeblack.png`}
            alt="Youtube"
          />
        </a>
      </div>
      <div
        className="flex flex-row gap-2 flex-wrap"
        style={{
          fontSize: "12px",
          lineHeight: "18px",
          textDecoration: "underline"
        }}
      >
        <a
          href="mailto:support-sb@ultrahorse.com"
          className="hover:text-amber-600"
        >
          Support
        </a>
        <a
          href="https://www.squadblast.com/terms-of-service"
          target="_blank"
          className="hover:text-amber-600"
        >
          Terms of Service
        </a>
        <a
          href="https://www.squadblast.com/privacy-policy"
          target="_blank"
          className="hover:text-amber-600"
        >
          Privacy Policy
        </a>
      </div>
      <span style={{ fontSize: "9px", color: "#808080" }}>
        © 2023 ULTRAHORSE Limited. SquadBlast and any associated logos are
        trademarks of ULTRAHORSE LIMITED.
      </span>
      <div className="flex flex-row gap-2 flex-wrap">
        <img
          src={`${process.env.NEXT_PUBLIC_CDN_URL}/email/Footer/Rating/PEGIblack.png`}
          alt="PEGI Rating"
        />
        <img
          src={`${process.env.NEXT_PUBLIC_CDN_URL}/email/Footer/Rating/ESRB.png`}
          alt="ESRB Rating"
        />
      </div>
    </footer>
  )
}

export default Footer
