import { getDeviceDeeplink } from "lib/deeplink"
import { loginWithGoogle } from "lib/firebase"
import { useStore } from "lib/zustandProvider"
import Link from "next/link"
import { useEffect, useRef, useState } from "react"
import ReactCodeInput from "react-code-input"
import GoogleButton from "react-google-button"
import { toast } from "react-hot-toast"
import { loginWithOneTimePassword } from "services/user"
import Button from "../form/base/Button"
import Popup from "./base/Popup"
import PopupHeader from "./base/PopupHeader"
import XSollaWidget from "../auth/XSollaWidget"

const inputStyle = {
  fontFamily: "Roboto",
  MozAppearance: "textfield",
  borderRadius: "6px",
  border: "1px solid",
  boxShadow: "0px 0px 10px 0px rgba(0,0,0,.10)",
  margin: "4px",
  width: "32px",
  height: "42px",
  fontSize: "32px",
  boxSizing: "border-box",
  textTransform: "uppercase",
  textAlign: "center"
}
const inputStyleInvalid = {
  ...inputStyle,
  background: "rgba(252, 165, 165, 0.8)"
}

function Login() {
  const codeLength = 6
  const showInstallPrompt = useStore((state) => state.showInstallPrompt)
  const user = useStore((state) => state.user)
  const isOpen = useStore((state) => state.loginVisible)
  const setLoginVisible = useStore((state) => state.setLoginVisible)

  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [code, setCode] = useState("")
  const [isLocalSelected, setLocalSelected] = useState(true)
  const [tabsEnabled, setTabsEnabled] = useState(
    !window.matchMedia("(min-width: 1024px)").matches
  )

  useEffect(() => {
    const change = (e) => setTabsEnabled(!e.matches)
    window.matchMedia("(min-width: 1024px)").addEventListener("change", change)

    return () => {
      window
        .matchMedia("(min-width: 1024px)")
        .removeEventListener("change", change)
    }
  }, [])

  const codeInputRef = useRef()

  const onClose = () => {
    setError("")
    setCode("")
    setLoginVisible(false)
  }

  const onOneTimePasswordSubmit = async (e) => {
    window.onbeforeunload = null
    setLoading(true)
    if (e) {
      e.preventDefault()
    }
    setError("")

    if (code.length !== codeLength) {
      setLoading(false)
      setError("Code must be 6 characters long")
      return
    }

    const response = await loginWithOneTimePassword(user.lastGamePlatform, code)

    if (!response) {
      setLoading(false)
      setError("Invalid code")
      return
    }
    window.localStorage.setItem("welcomeToast", "issue")
    window.location.reload()
  }

  useEffect(() => {
    if (code.length === codeLength) {
      onOneTimePasswordSubmit()
    }
  }, [code])

  useEffect(() => {
    const handlePasteAnywhere = (event) => {
      event.preventDefault()
      const paste = event.clipboardData.getData("text")

      if (paste.length === codeLength) {
        setCode(paste)
        if (codeInputRef) {
          const pasteArr = paste.split("")
          for (let i = 0; i < pasteArr.length; i++) {
            codeInputRef.current.state.input[i] = pasteArr[i]
          }
          toast("Code Pasted", {
            icon: "📋",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff"
            }
          })
        }
      }
    }

    if (isOpen) {
      window.addEventListener("paste", handlePasteAnywhere)
    }

    return () => {
      window.removeEventListener("paste", handlePasteAnywhere)
    }
  }, [isOpen])

  const emailEnabled = process.env.NEXT_PUBLIC_XSOLLA_LOGIN_ENABLED === true
  const showLocal = tabsEnabled ? isLocalSelected : true
  const showEmail = tabsEnabled ? !isLocalSelected && emailEnabled : emailEnabled

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      className="font-roboto"
      dialogPosition="top-2 left-0 right-0 lg:top-0 lg:bottom-0"
    >
      <PopupHeader title="Login" onClose={onClose} />
      <ul class="lg:hidden flex flex-wrap text-sm font-medium text-center text-gray-500 dark:text-gray-400 p-4">
        <li class="mr-2">
          <a
            href="#"
            className={`${
              isLocalSelected
                ? "inline-block px-4 py-3 text-white bg-button-primary rounded-lg active"
                : "inline-block px-4 py-3 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white"
            }`}
            aria-current="page"
            onClick={() => {
              setLocalSelected(true)
            }}
          >
            Link Your Account
          </a>
        </li>
        <li class="mr-2">
          <a
            href="#"
            className={`${
              !isLocalSelected
                ? "inline-block px-4 py-3 text-white bg-button-primary rounded-lg active"
                : "inline-block px-4 py-3 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white"
            }`}
            onClick={() => {
              setLocalSelected(false)
            }}
          >
            Login by Email
          </a>
        </li>
      </ul>
      <div className="flex flex-row justify-center items-center flex-wrap relative">
        {/* <GoogleButton onClick={loginWithGoogle} />
        <span className="block w-full border-t-2 border-gray-700 mt-3 "></span> */}
        {showLocal && (
          <div className="flex flex-col items-center">
            <h2 className="hidden lg:block text-lg lg:text-2xl text-button-primary font-tahoma flex-1">
              Link Your Account
            </h2>
            <form
              className="w-full p-3 flex flex-col lg:justify-center items-center gap-5 flex-wrap flex-1 py-6"
              style={{ minHeight: "550px" }}
              onSubmit={onOneTimePasswordSubmit}
            >
              <div className="flex flex-col gap-3 justify-center items-center">
                <ReactCodeInput
                  ref={codeInputRef}
                  inputStyle={inputStyle}
                  inputStyleInvalid={inputStyleInvalid}
                  type="text"
                  fields={codeLength}
                  isValid={error === ""}
                  autoComplete={false}
                  value={code}
                  autoFocus={true}
                  onChange={(e) => setCode(e)}
                />
                {error !== "" && (
                  <p className="text-red-500 text-xs italic">{error}</p>
                )}
                <Button
                  type={["primary", "large"]}
                  className="w-64 h-12"
                  loading={loading}
                >
                  Login
                </Button>
              </div>
              <div className="flex flex-col gap-3 justify-center items-center w-64 lg:w-96">
                <p className="text-white mt-3 -mb-3 text-sm lg:text-base">
                  How to find Link Code:
                </p>
                <ul className="list-square list-inside font-tahoma text-gray-400 text-xs lg:text-sm">
                  <li>
                    <Link
                      onClick={(e) => {
                        e.preventDefault()
                        showInstallPrompt(getDeviceDeeplink(user))
                      }}
                      href={getDeviceDeeplink()}
                      target="blank"
                      className="text-sky-500 hover:text-sky-700"
                    >
                      Launch SquadBlast
                    </Link>
                  </li>
                  <li>Open Settings in top right corner</li>
                  <li>Open Accounts tab, tap Link Your Account</li>
                </ul>
              </div>
            </form>
          </div>
        )}
        {showEmail && (
          <div
            style={{ backgroundColor: "#111D29" }}
            className="flex-1 py-6 flex flex-col items-center relative"
          >
            {!tabsEnabled && (
              <h2 className="absolute top-6 -left-4 text-2xl text-sky-100 font-tahoma">
                OR
              </h2>
            )}
            <h2 className="hidden lg:block text-lg lg:text-2xl text-sky-100 font-tahoma">
              Login by Email
            </h2>
            <div
              style={{ width: "355px", height: "550px", overflow: "hidden" }}
            >
              <XSollaWidget />
            </div>
          </div>
        )}
      </div>
    </Popup>
  )
}

export default Login
