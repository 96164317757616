import { useMemo } from "react"
import { mountStoreDevtool } from "simple-zustand-devtools"
import { create } from "zustand"

let store

const initialState = {
  user: {
    authenticated: false,
    name: "",
    balance: 0,
    image: process.env.NEXT_PUBLIC_DEFAULT_USER_IMAGE
  },
  babkaToken: null,
  devMode: false,
  wikiMode: false,
  storeEnabled: true,
  loginVisible: false,
  installPromptVisible: false,
  installPromptAction: "",
  token: null,
  fullScreenTrigger: 0,
  minimizeTrigger: 0,
  volume: 0,
  isGameLoading: true,
  externalLinkPromptVisible: false,
  externalLink: ""
}

function initStore(preloadedState = initialState) {
  return create((set, get) => ({
    ...initialState,
    ...preloadedState,
    setToken: (token) => {
      set({
        token
      })
    },
    setBabkaToken: (token) => {
      set({
        babkaToken: token
      })
    },
    setUser: (userInfo) => {
      // set default image if no image provided
      if (!userInfo.image?.length) {
        userInfo.image = process.env.NEXT_PUBLIC_DEFAULT_USER_IMAGE
      }
      set({
        user: { authenticated: true, ...userInfo }
      })
    },
    setLoginVisible: (value) => {
      set({
        loginVisible: value
      })
    },
    setDevMode: (value) => {
      set({
        devMode: value
      })
    },
    setWikiMode: (value) => {
      set({
        wikiMode: value
      })
    },
    showInstallPrompt: (action) => {
      set({
        installPromptVisible: true,
        installPromptAction: action
      })
    },
    hideInstallPrompt: () => {
      set({
        installPromptVisible: false,
        installPromptAction: ""
      })
    },
    setStoreEnabled: (value) => {
      set({
        storeEnabled: value
      })
    },
    goFullScreen: () => {
      set((state) => ({
        fullScreenTrigger: state.fullScreenTrigger + 1
      }))
    },
    minimizeGame: () => {
      set((state) => ({
        minimizeTrigger: state.minimizeTrigger + 1
      }))
    },
    setVolume: (value) => {
      window.localStorage.setItem("pageVolume", value)
      set({ volume: value })
    },
    setGameLoading: (value) => {
      set({ isGameLoading: value })
    },
    showExternalLinkPrompt: (link) => {
      set({
        externalLinkPromptVisible: true,
        externalLink: link
      })
    },
    hideExternalLinkPrompt: () => {
      set({
        externalLinkPromptVisible: false,
        externalLink: ""
      })
    }
  }))
}

export const initializeStore = (preloadedState) => {
  let _store = store ?? initStore(preloadedState)

  // After navigating to a page with an initial Zustand state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState
    })
    // Reset the current store
    store = undefined
  }

  // For SSG and SSR always create a new store
  if (typeof window === "undefined") return _store
  // Create the store once in the client
  if (!store) {
    store = _store
    if (process.env.NODE_ENV === "development") {
      mountStoreDevtool("Store", store) //FIXME: Produces render error
    }
  }

  return _store
}

export function useHydrate(initialState) {
  const state =
    typeof initialState === "string" ? JSON.parse(initialState) : initialState
  const store = useMemo(() => initializeStore(state), [state])
  return store
}
