import { initializeApp } from "firebase/app"
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut
} from "firebase/auth"
import { deleteCookies, loginWithGoogleFirebase } from "services/user"

const firebaseConfig = {
  apiKey: "AIzaSyAPdoh9eYXvSKx61Y1kqeJfIv9WDPGChCw",
  authDomain: "uh-market.firebaseapp.com",
  projectId: "uh-market",
  storageBucket: "uh-market.appspot.com",
  messagingSenderId: "471241430778",
  appId: "1:471241430778:web:0d3f494c5a8c710ec75b43"
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

export const loginWithGoogle = async () => {
  try {
    const googleAuthProvider = new GoogleAuthProvider()
    const result = await signInWithPopup(auth, googleAuthProvider)
    const token = await auth.currentUser.getIdToken(true)
    await loginWithGoogleFirebase(token)

    window.location.reload()
  } catch (error) {
    console.log(error)
  }
}

export const logout = async () => {
  window.onbeforeunload = null
  await signOut(auth)
  await deleteCookies()

  try {
    var req = indexedDB.deleteDatabase("/idbfs")
    req.onsuccess = function () {
      console.log("Deleted database successfully")
      window.location.href = "/"
    }
    req.onerror = function () {
      console.log("Couldn't delete database")
      window.location.href = "/"
    }
    req.onblocked = function () {
      console.log("Couldn't delete database due to the operation being blocked")
      window.location.href = "/"
    }
  } catch (error) {
    console.log(error)
    window.location.href = "/"
    // window.location.reload()
  }
}
