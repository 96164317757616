import { request } from "lib/request"
import { getUA, isMobile, mobileVendor, osName } from "react-device-detect"

export const loginWithGoogleFirebase = async (firebaseToken) => {
  return request(
    `${process.env.NEXT_PUBLIC_SERVER_URL}/user/login-with-google-firebase`,
    { method: "POST", body: { firebaseToken } }
  )
}

export const loginWithOneTimePassword = async (platform, password) => {
  return request(
    `${process.env.NEXT_PUBLIC_SERVER_URL}/user/login-with-one-time-password`,
    {
      method: "POST",
      body: { password, osName, mobileVendor, isMobile, ua: getUA, platform }
    }
  )
}

export const loginCrazyGames = async (crazyGamesToken) => {
  return request(`${process.env.NEXT_PUBLIC_SERVER_URL}/user/crazygames`, {
    method: "POST",
    body: { crazyGamesToken, osName, mobileVendor, isMobile, ua: getUA }
  })
}

export const getCurrentUser = async (token) => {
  return (
    await request(`${process.env.NEXT_PUBLIC_SERVER_URL}/user/me`, {
      token
    })
  ).data
}

export const deleteCookies = async () => {
  return (
    await request(`${process.env.NEXT_PUBLIC_SERVER_URL}/user/delete-cookies`, {
      method: "DELETE"
    })
  ).data
}
