function Bubble({ className }) {
  return (
    <div
      className={`rounded-full bg-white w-4 h-4 text-uh-background text-xs text-center font-bold ${className}`}
    >
      4
    </div>
  )
}

export default Bubble
