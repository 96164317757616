import { useStore } from "lib/zustandProvider"
import { useRouter } from "next/router"
import React, { useEffect, useState } from "react"
import { isMobile, isWindows } from "react-device-detect"

function NavigationItems({ classesActive, classesInactive }) {
  const router = useRouter()
  const user = useStore((state) => state.user)
  const devMode = useStore((state) => state.devMode)
  const [renderClientSide, setRenderClientSide] = useState(false)

  const fixedPlayUrl = process.env.NEXT_PUBLIC_PLAY_URL || "false"

  useEffect(() => {
    setRenderClientSide(true)
  }, [])

  return (
    <>
      <a
        href="/"
        className={`uppercase ${
          router.pathname === "/" || router.pathname.startsWith("/items")
            ? classesActive
            : classesInactive
        }`}
      >
        Market
      </a>
      {process.env.NEXT_PUBLIC_INAPPS_ENABLED === "true" && (
        <a
          href="/store"
          className={`uppercase ${
            router.pathname === "/store" ? classesActive : classesInactive
          }`}
        >
          Store
        </a>
      )}
      <a
        href="https://www.squadblast.com/universe"
        target="_BLANK"
        className={`uppercase ${classesInactive}`}
      >
        Universe
      </a>
      <a
        href="https://www.squadblast.com/patchnotes?category=Major%20Update"
        target="_BLANK"
        className={`uppercase ${classesInactive}`}
      >
        Updates
      </a>
      {process.env.NEXT_PUBLIC_WEBSHOP_ENABLED === "true" && (
        <a
          href="https://webshop.squadblast.com/"
          target="_BLANK"
          className={`uppercase ${classesInactive}`}
        >
          WebShop
        </a>
      )}
      {renderClientSide && fixedPlayUrl !== "false" && (
        <a
          href={fixedPlayUrl + (isMobile ? "/play/mobile" : "/play")}
          className={`uppercase bg-emerald-300 bg-opacity-40 whitespace-nowrap ${
            router.pathname === "/play" ? classesActive : classesInactive
          }`}
        >
          🕹️ Play
        </a>
      )}
      {renderClientSide && fixedPlayUrl === "false" && (
        <a
          href={isMobile ? "/play/mobile" : "/play"}
          className={`uppercase bg-emerald-300 bg-opacity-40 whitespace-nowrap ${
            router.pathname === "/play" ? classesActive : classesInactive
          }`}
        >
          🕹️ Play
        </a>
      )}
      {renderClientSide && isWindows && (
        <a
          href="https://installer.launcher.xsolla.com/xlauncher-builds/xsolla-launcher-update/5814/bin/web_installer.exe"
          target="_BLANK"
          className={`uppercase ${classesInactive}`}
        >
          Download
        </a>
      )}
      {devMode && (
        <a
          href="/play/prerelease"
          className={`uppercase whitespace-nowrap ${
            router.pathname === "/play/prerelease"
              ? classesActive
              : classesInactive
          }`}
        >
          Pre-Release
        </a>
      )}
    </>
  )
}

export default NavigationItems
