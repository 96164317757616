import { getDeviceToastPosition } from "lib/deeplink"
import { useStore } from "lib/zustandProvider"
import { useEffect, useState } from "react"
import { Toaster } from "react-hot-toast"
import Login from "../popup/Login"
import Footer from "./Footer"
import Header from "./header/Header"
import MainNavigation from "./navigation/MainNavigation"
import { GoogleAnalytics } from "../base/GoogleAnalytics"
import { MetaPixel } from "../base/MetaPixel"
import InstallOrOpen from "../popup/InstallOrOpen"
import {
  Locale,
  Metaframe,
  Profile,
  Wallet,
  Orbs,
  Backpack
} from "@xsolla/metaframe-react-sdk"
import ExternalLink from "../popup/ExternalLink"
import { useRouter } from "next/router"

function Layout({ children }) {
  const router = useRouter()
  const devMode = useStore((state) => state.devMode)
  const user = useStore((state) => state.user)
  const babkaToken = useStore((state) => state.babkaToken)
  const [renderClientSide, setRenderClientSide] = useState(false)

  useEffect(() => {
    setRenderClientSide(true)
  }, [])

  return (
    <div className={`flex flex-col min-h-screen font-roboto`}>
      {renderClientSide && <Toaster position={getDeviceToastPosition()} />}
      {renderClientSide && <Login />}
      {renderClientSide && <InstallOrOpen />}
      {renderClientSide && <ExternalLink />}
      {renderClientSide && (
        <GoogleAnalytics
          trackPageViews
          gaMeasurementId="G-JVKBZRNERP"
          userId={user.uid}
        />
      )}
      {renderClientSide && <MetaPixel />}
      {renderClientSide && devMode && (
        <Metaframe
          loginProjectId="0005b3af-45d1-4c11-bae1-4aaba8d76a80"
          locale={Locale.en_US}
          shortcutKeys={["ShiftLeft", "Tab"]}
          onLogin={(token) => {
            console.log("token", token)
          }}
        >
          <Wallet />
          <Backpack />
          <Orbs />
          <Profile />
        </Metaframe>
      )}
      <div className="fixed top-0 left-0 right-0 bg-uh-background z-10 shadow">
        <Header />
        <MainNavigation />
      </div>
      <div className="mt-32 tablet:mt-20">{children}</div>
      {!router.pathname.startsWith("/play") && <Footer />}
    </div>
  )
}

export default Layout
