import Head from "next/head"

function PageHead({ subtitle, description, image, type, titleOverride }) {
  const title = `ULTRAHORSE Market ${subtitle ? `- ${subtitle}` : ""}`
  const defaultDescription =
    "SquadBlast is a multiplayer side-scrolling shooter. Its team-based battles are a fresh experience: all the depth of a modern FPS, yet incredibly easy to pick up and play."
  const defaultImage = `${process.env.NEXT_PUBLIC_CDN_URL}/squadblast_cover.png`
  const defaultType = "website"

  return (
    <Head>
      <title>{titleOverride || title}</title>
      <meta
        name="description"
        content={description || defaultDescription}
        key="description"
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1"
        key="viewport"
      />
      <link rel="icon" href="/favicon.ico" key="favicon" />
      <link
        rel="preconnect"
        href={process.env.NEXT_PUBLIC_CDN_URL}
        key="preconnect_cdn"
      />
      <link
        rel="preconnect"
        href="https://fonts.googleapis.com"
        key="preconnect_googleapis"
      />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossorigin
        key="preconnect_gstatic"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap"
        rel="stylesheet"
        fetchpriority="high"
        key="font_roboto"
      ></link>

      <meta property="og:title" content={title} key="og_title" />
      <meta property="og:type" content={type || defaultType} key="og_type" />
      <meta
        property="og:description"
        content={description || defaultDescription}
        key="og_description"
      />
      <meta
        property="og:image"
        content={image || defaultImage}
        key="og_image"
      />
      <meta
        name="twitter:card"
        content="summary_large_image"
        key="twitter_card"
      ></meta>
    </Head>
  )
}

export default PageHead

//https://market.squadblast.com/_next/image?url=/items/original/${item.image}&w=256&q=75
