import { usePageViews } from "lib/googleAnalytics"
import Script from "next/script"

export function GoogleAnalytics(props) {
  const {
    debugMode,
    gaMeasurementId,
    gtagUrl,
    strategy,
    defaultConsent,
    trackPageViews,
    userId,
    nonce
  } = {
    debugMode: false,
    gtagUrl: "https://www.googletagmanager.com/gtag/js",
    strategy: "afterInteractive",
    defaultConsent: "granted",
    ...props
  }

  const _gaMeasurementId =
    process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID ?? gaMeasurementId

  usePageViews({
    gaMeasurementId: _gaMeasurementId,
    ignoreHashChange:
      typeof trackPageViews === "object"
        ? trackPageViews?.ignoreHashChange
        : false,
    disabled: !trackPageViews,
    userId
  })

  if (!_gaMeasurementId) {
    return null
  }

  return (
    <>
      <Script
        src={`${gtagUrl}?id=${_gaMeasurementId}`}
        strategy={strategy}
        crossOrigin="anonymous"
      />
      <Script id="nextjs-google-analytics" nonce={nonce}>
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            ${
              defaultConsent === "denied"
                ? `gtag('consent', 'default', {
              'ad_storage': 'denied',
              'analytics_storage': 'denied'
            });`
                : ``
            }
            gtag('config', '${_gaMeasurementId}', {
              page_path: window.location.pathname,
              ${debugMode ? `debug_mode: ${debugMode},` : ""}
              ${userId ? `user_id: "${userId}",` : ""}
            });
          `}
      </Script>
    </>
  )
}
