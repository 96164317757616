import Popup from "./base/Popup"
import PopupHeader from "./base/PopupHeader"
import { useStore } from "lib/zustandProvider"
import Button from "../form/base/Button"
import {
  getDeviceInstallLink,
  getDevicePlatform,
  normalizeServerPlatform
} from "lib/deeplink"
import { useEffect } from "react"

function InstallOrOpen() {
  const user = useStore((state) => state.user)
  const isOpen = useStore((state) => state.installPromptVisible)
  const action = useStore((state) => state.installPromptAction)
  const hideInstallPrompt = useStore((state) => state.hideInstallPrompt)

  useEffect(() => {
    if (isOpen) {
      // Check weather should show this popup or dirrectly go
      // target platform = current platform
      // already seen this session
      const alreadySeen =
        sessionStorage.getItem("InstallOrOpenAlreadySeen") !== null
      const samePlatform = user.lastGamePlatform
        ? normalizeServerPlatform(user.lastGamePlatform) === getDevicePlatform()
        : false

      if (alreadySeen || samePlatform) {
        onContinue()
        onClose()
      }
    }
  }, [isOpen])

  const onClose = () => {
    markAsAlreadySeen()
    hideInstallPrompt()
  }

  const onContinue = () => {
    window.open(action, "_blank")
    onClose()
  }

  const onInstall = () => {
    window.open(getDeviceInstallLink(), "_blank")
    markAsAlreadySeen()
  }

  const markAsAlreadySeen = () => {
    sessionStorage.setItem("InstallOrOpenAlreadySeen", true)
  }

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      className="font-roboto"
      dialogPosition="top-2 left-0 right-0 lg:top-0 lg:bottom-0"
      zIndex="z-50"
    >
      <PopupHeader title="Got SquadBlast?" onClose={onClose} />
      <div className="my-6 mx-8 flex flex-col justify-center items-center">
        <p className="text-white text-opacity-70">
          You need to have the game installed on your device before you can
          access this functionality. <br /> Do you have SquadBlast installed on
          your device?
        </p>
        <div className="mt-4 flex flex-row gap-4">
          <Button type={["blue", "large"]} onClick={onContinue}>
            Yes, SquadBlast is installed
          </Button>
          <Button type={["primary", "large"]} onClick={onInstall}>
            No, I need SquadBlast!
          </Button>
        </div>
      </div>
    </Popup>
  )
}

export default InstallOrOpen
