import Layout from "@/components/layout/Layout"
import "@/styles/globals.css"
import Head from "next/head"
import { useHydrate } from "../lib/store"
import { StoreProvider } from "../lib/zustandProvider"
import PageHead from "@/components/layout/PageHead"
import { useEffect } from "react"

export default function App({ Component, pageProps }) {
  const store = useHydrate(pageProps.initialStoreState)

  // useEffect(() => {
  //   if ("serviceWorker" in navigator) {
  //     console.log("ServiceWorker available")
  //     navigator.serviceWorker
  //       .register("/service-worker.js")
  //       .then((registration) =>
  //         console.log(
  //           "ServiceWorker registered; scope is: ",
  //           registration.scope
  //         )
  //       )
  //   }
  // }, [])

  return (
    <StoreProvider store={store}>
      <Layout>
        <PageHead />
        <Component {...pageProps} />
      </Layout>
    </StoreProvider>
  )
}
