import { useEffect, useState } from "react"
import { ClipLoader } from "react-spinners"

const typeStyles = {
  primary: "bg-button-primary",
  info: "border-2 border-slate-500",
  blue: "bg-sky-600",
  gray: "bg-gray-500",
  transparent: "bg-transparent",
  small: "text-xs lg:text-sm",
  large: "text-xl",
  disabled: "bg-slate-600 text-white text-opacity-50"
}

const mergeStyles = (styles) => {
  let endStyle = ""

  if (styles) {
    if (Array.isArray(styles)) {
      styles.forEach((style) => {
        endStyle += ` ${typeStyles[style]}`
      })
    } else {
      endStyle += ` ${typeStyles[styles]}`
    }
  } else {
    endStyle += ` ${typeStyles["primary"]}`
  }

  return endStyle
}

function Button({ children, className, type, onClick, loading, ...rest }) {
  const [getLoading, setLoading] = useState(false)

  useEffect(() => {
    if (loading !== undefined) {
      setLoading(loading)
    }
  }, [loading])

  if (rest.disabled) {
    type = "disabled"
  }
  const typeStyle = mergeStyles(type)
  const setLoadingCallback = (value) => {
    setLoading(value)
  }
  return (
    <button
      className={`px-1 lg:px-5 py-1 text-white ${typeStyle} ${className}`}
      onClick={(e) => {
        if (onClick) {
          onClick(e, setLoadingCallback)
        }
      }}
      {...rest}
    >
      {getLoading ? (
        <div style={{ marginTop: "3px" }}>
          <ClipLoader color="#ffffff" size="14px" />
        </div>
      ) : (
        children
      )}
    </button>
  )
}

export default Button
