import NavigationItems from "./NavigationItems"

function HeaderNavPC() {
  const classesActive =
    "text-amber-600 px-2 xl:px-6 h-full flex items-center bg-slate-300 bg-opacity-10 rounded-sm"
  const classesInactive =
    "text-slate-300 hover:text-amber-500 px-2 xl:px-6 h-full flex items-center hover:bg-slate-300 hover:bg-opacity-10 rounded-sm"

  return (
    <nav
      className={`h-3/4 flex-row items-center px-5 text-white hidden tablet:flex`}
    >
      <NavigationItems
        classesActive={classesActive}
        classesInactive={classesInactive}
      />
    </nav>
  )
}

export default HeaderNavPC
