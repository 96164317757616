import { Dialog } from "@headlessui/react"

function Popup({
  children,
  isOpen,
  onClose,
  className,
  dialogPosition,
  skipBackdrop,
  zIndex
}) {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className={`relative ${zIndex ? zIndex : "z-30"}`}
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      {!skipBackdrop && (
        <div className="fixed inset-0 bg-gray-900" aria-hidden="true" />
      )}

      {/* Full-screen container to center the panel */}
      <div
        className={`fixed ${
          dialogPosition ? dialogPosition : "inset-0"
        } flex items-center justify-center p-4`}
      >
        {/* The actual dialog panel  */}
        <Dialog.Panel
          className={`mx-auto rounded w-popup h-min-popup bg-uh-background border-2 border-slate-500 ${className}`}
        >
          {children}
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}

export default Popup
