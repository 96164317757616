const serializeParams = (obj) => {
  var str = []
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]))
    }
  return str.join("&")
}

export const request = async (path, options) => {
  let fetchOptions = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    query: {},
    ...options
  }

  if (fetchOptions.token) {
    fetchOptions.headers.Authorization = `Bearer ${fetchOptions.token}`
    fetchOptions.query.token = fetchOptions.token
  }

  if (fetchOptions.body && typeof fetchOptions.body !== "string") {
    fetchOptions.body = JSON.stringify(fetchOptions.body)
  }

  // if relative path, apply api domain
  if (path.indexOf("http://") !== 0 && path.indexOf("https://") !== 0) {
    if (path.indexOf("/") !== 0) {
      path = `/${path}`
    }
    path = `${process.env.NEXT_PUBLIC_SERVER_URL}${path}`
  }

  if (fetchOptions.query && Object.keys(fetchOptions.query).length) {
    path = `${path}?${serializeParams(fetchOptions.query)}`
  }

  try {
    console.log("Request: ", path)
    console.log(JSON.stringify(fetchOptions))
    console.log("Request Token: ", fetchOptions.token)
    const response = await fetch(path, fetchOptions)

    console.log("Response: ", JSON.stringify(response))
    if (!response.ok) {
      throw new Error(
        `Request failed with error code: ${response.status} ${response.statusText}`
      )
    }

    const { data, errors, success } =
      response.status !== 204 ? await response.json() : { success: true }

    if (!success) throw new Error(errors)

    return { data, response }
  } catch (error) {
    console.log("There was an error", error)
  }
}
