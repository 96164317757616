import { useRouter } from "next/router"
import { useEffect } from "react"

export function MetaPixel() {
  const router = useRouter()

  let ReactPixel = null

  const onRouteChange = () => {
    if (!ReactPixel) {
      return
    }
    ReactPixel.pageView()
  }

  useEffect(() => {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((rp) => {
        rp.init("1370287040212680") // facebookPixelId
        rp.pageView()
        ReactPixel = rp

        router.events.on("routeChangeComplete", onRouteChange)
      })

    return () => {
      router.events.off("routeChangeComplete", onRouteChange)
      ReactPixel = null
    }
  }, [router.events])

  return null
}
