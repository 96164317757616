import Button from "@/components/form/base/Button"
import Image from "next/image"
import React from "react"

function PopupHeader({ onClose, title }) {
  return (
    <h1
      className={`bg-black bg-opacity-40 text-white pl-8 pr-2 py-4 uppercase text-4xl flex`}
    >
      <span className="flex-grow">{title}</span>
      <Button onClick={onClose} type="plain">
        <Image src="/icons/close.svg" width={30} height={30} alt="close" />
      </Button>
    </h1>
  )
}

export default PopupHeader
