import Bubble from "@/components/base/Bubble"
import Image from "next/image"

function Notifications() {
  return (
    <div className="relative">
      <Image src="/icons/bell.svg" width={18} height={21} alt="Notifications" />
      <Bubble className="absolute -top-1 -right-2" />
    </div>
  )
}

export default Notifications
