import { Fragment, useEffect, useState } from "react"
import { Menu } from "@headlessui/react"
import Image from "next/image"
import { logout } from "lib/firebase"
import { useStore } from "lib/zustandProvider"
import { useRouter } from "next/router"

function Hamburger() {
  const router = useRouter()
  const devMode = useStore((state) => state.devMode)
  const user = useStore((state) => state.user)

  return (
    <Menu className="relative" as="div">
      <Menu.Button>
        <Image
          src="/icons/hamburger.svg"
          alt="Hamburger Menu"
          width={32}
          height={25}
          className="w-8"
        />
      </Menu.Button>
      <Menu.Items>
        <div className="bg-slate-600 absolute top-10 right-0 py-3 px-2 w-40 shadow-md z-50 flex flex-col">
          {devMode && (
            <Menu.Item key="webshopredirect" as={Fragment}>
              {({ active }) => (
                <button
                  className="hover:underline"
                  onClick={() => {
                    router.push({
                      pathname: "/",
                      query: { webshopredirect: "" }
                    })
                  }}
                >
                  Toggle Webshop Redirect
                </button>
              )}
            </Menu.Item>
          )}
          {devMode && (
            <Menu.Item key="exitdevmode" as={Fragment}>
              {({ active }) => (
                <button
                  className="hover:underline"
                  onClick={() => {
                    router.push({ pathname: "/", query: { exitdevmode: true } })
                  }}
                >
                  Exit Dev Mode
                </button>
              )}
            </Menu.Item>
          )}
          {user.admin && (
            <Menu.Item key="admin" as={Fragment}>
              {({ active }) => (
                <button
                  className="hover:underline"
                  onClick={() => {
                    router.push({ pathname: "/admin/items" })
                  }}
                >
                  Admin
                </button>
              )}
            </Menu.Item>
          )}
          <Menu.Item key="logout" as={Fragment}>
            {({ active }) => (
              <button
                className="hover:underline"
                onClick={() => {
                  window.onbeforeunload = null
                  window.location.href = "/logout"
                }}
              >
                Logout
              </button>
            )}
          </Menu.Item>
        </div>
      </Menu.Items>
    </Menu>
  )
}

export default Hamburger
